
import AdminContentEditable from '~/mixins/AdminContentEditable'
import Modal from '@/components/Shared/Modal/index.vue'
export default {
  mixins: [AdminContentEditable],
  data() {
    return {
      show: false,
      newSectionModal: false,
      allComponentList: [
        // { name: 'Banner', type: 1 },
        { name: 'About1', type: 2 },
        { name: 'WebSiteVideo', type: 3 },
        { name: 'Showcase', type: 4 },
        { name: 'Category2', type: 5 },
        { name: 'PopularProducts', type: 6 },
        { name: 'PopularCategories', type: 7 },
        // { name: 'Types', type: 9 },
        { name: 'Blogs', type: 10 },
        { name: 'Sponsor', type: 11 },
        { name: 'Donetors', type: 11 },
        { name: 'MoreAboutMe', type: 12 },
        { name: 'SeancesList', type: 13 },
        { name: 'EventListOfUsList', type: 14 },
        { name: 'Testimonials', type: 15 },
      ],
      selectedIndexOfComponentList: -1,
      componentList:
        this.$store.state.settings.components !== null
          ? this.$store.state.settings.components.split(',')
          : [],
    }
  },
  components: {
    Modal,
  },
  methods: {
    SELECT_INDEX_OF_COMPONENT_LIST(i) {
      this.selectedIndexOfComponentList = i + 1
      this.newSectionModal = true
    },
    array_move(arr, old_index, new_index, e) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
      return arr // for testing
    },
    SAVE_COMPONENTS() {
      const componentsString = this.componentList.join(',')
      // val, req, key, type, id
      this.UPDATE_CONTEXT(
        componentsString,
        `Companies/${this.getSettings.id}`,
        'Components',
        1,
        this.getSettings.id
      )
      this.SAVE_CONTEXT(
        'PUT',
        `Companies/${this.getSettings.id}`,
        this.getSettings.id,
        ['Components'],
        this.UPDATE_COMPONENTS
      )
    },
    UPDATE_COMPONENTS() {
      this.$emit('update:components', this.componentList);
      this.show = false;
    },
  },
}
